<template>
  <div class="form-wrap" v-loading="loading">
      <h3 class="title">{{title}}</h3>
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="120px">
          <el-form-item label="标题:" prop="title">
            <el-input v-model="form.title" />
          </el-form-item>
          <el-form-item label="原文地址：" prop="url">
            <el-input @change="showSourceName" v-model="form.url" />
          </el-form-item>
          <el-form-item label="发布时间：" prop="publishdateStd" class="publish" v-show="this.type==='add'">
            <el-date-picker v-model="form.publishdateStd" type="datetime" @change="$forceUpdate()"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
        <el-form-item label="发布时间：" prop="publishdateStd" class="publish" v-show="this.type==='edit'">
          <el-date-picker v-model="form.publishdateStd" type="datetime" @change="$forceUpdate()"  value-format="yyyy-MM-dd HH:mm:ss" disabled></el-date-picker>
        </el-form-item>
        <el-form-item label="发布者:" prop="authorName">
          <el-input v-model="form.authorName" />
        </el-form-item>
        <el-form-item label="地域:" prop="location">
          <el-input v-model="form.location" />
        </el-form-item>
          <el-form-item label="自定义媒体：" v-show="this.type==='add'" prop="customeSource">
            <el-input disabled v-model="form.customeSource" />
          </el-form-item>
        <el-form-item label="自定义媒体：" v-show="this.type==='edit'" prop="customeSource">
          <el-input disabled v-model="form.customeSource" />
        </el-form-item>
          <el-form-item label="其它选项：">
            <span class="el-dropdown-link" @click="dropUp" v-if="openFilter">
              收起<i class="el-icon-arrow-up el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" @click="dropUp" v-else>
              展开<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </el-form-item>
          <el-form-item label="正负面：" class="plusMinus" v-if="openFilter" prop="plusMinus">
            <el-select v-model="form.emotionType" @change="select_status">
              <el-option
                key="0"
                label="正面"
                value="0">正面
              </el-option>
              <el-option
                key="1"
                label="负面"
                value="1">负面
              </el-option>
              <el-option
                key="2"
                label="敏感"
                value="2">敏感
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类：" class="sort" v-if="openFilter && editStatus" prop="sorts" v-show="false">
            <el-select v-model="form.nodeId">
              <el-option
                v-for="item in node"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>

          </el-form-item>
<!--          <el-form-item label="标签：" class="tag" v-if="openFilter && editStatus" prop="tag" v-show="editStatus">-->
<!--              <el-select v-model="form.markList">-->
<!--                <el-option-->
<!--                  v-for="item in options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
          <el-form-item label="摘要：" class="required" prop="abstract">
            <el-input type="textarea" placeholder="请输入摘要" :rows="3" v-model="form.summary"></el-input>
          </el-form-item>
          <el-form-item label="正文:" class="required" prop="content">
<!--            <vue-ueditor-wrap v-model="form.body" :config="myConfig"></vue-ueditor-wrap>-->
<!--            <div class="warning" v-if="isEmpty">请输入正文内容</div>-->
            <el-input
              type="textarea"
              :rows="20"
              placeholder="请输入正文内容"
              v-model="form.body">
            </el-input>
          </el-form-item>

        <el-form-item v-for="field in extendFields" :key="field.tagId" :label="field.tagDisplay" :prop="field.tagId">
          <el-input v-model="form[field.bindPos]" />
        </el-form-item>

          <el-form-item>
            <el-button type="primary" size="medium" v-if="type == 'add'" @click="save">保存</el-button>
            <el-button type="primary" size="medium" v-else-if="type == 'edit'" @click="updateNews">修改</el-button>
            <!-- <el-button type="primary" size="medium" v-else-if="type == 'continued'" @click="reset">继续添加</el-button> -->
            <el-button size="medium" @click="toBack">返回</el-button>
          </el-form-item>
        </el-form>
    </div>
  </template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import { listAllNodeWithMS, customSource, save, showOneNewsData, update } from '@/api/detection'
import { extendFieldsList } from '@/api/sysTag'
export default {
  components: {
    VueUeditorWrap
  },
  data () {
    return ({
      options: [{}],
      node: [],
      type: 'add',
      title: '新增数据',
      openFilter: true,
      isEmpty: false,
      loading: false,
      form: {
        location:'',
        authorName: '',
        title: '',
        url: '',
        publishdateStd: '',
        customeSource: '',
        emotionType: '0',
        nodeId: '',
        oldNodeId: '',
        // markList: [],
        summary: '',
        body: '',
        themeId: '',
        id: 0,
        tag_1: '',
        tag_2: '',
        tag_3: '',
        tag_4: '',
        tag_5: '',
        tag_6: '',
        tag_7: '',
        tag_8: '',
        tag_9: '',
        tag_10: ''
      },
      form2: {
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        sourceType: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        timeType: '5',
        pubDateFrame: [], // 时间区间
        startDate: '2010-01-01 00:00:00',
        endDate: '2030-12-12 23:59:59',
        emotionType: '-1',
        isTag: '-1',
        scope: '1',
        desc: '',
        keyWord: '',
        classifyRule: [],
        nodeids: [],
        sn: '',
        pageSize: 1,
        current: 1
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请选择原文地址', trigger: 'blur' }
        ],
        publishdateStd: [
          { type: 'string', required: true, message: '请选择发布时间', trigger: 'change' }
        ],
        customeSource: [
          { required: true, message: '请输入媒体方式', trigger: 'blur' }
        ]
      },
      editStatus: true,
      myConfig: {
        serverUrl: '',
        UEDITOR_HOME_URL: '/UEditor/'
      },
      extendFields: []
    })
  },
  mounted () {
    if (this.$route.params.editType === '1') {
      this.editStatus = false
    }
    this.type = this.$route.meta.type
    this.listAllNode()
    if (this.type === 'edit') {
      window.scrollTo(0, 0)
      this.title = '修改数据'
      this.form2.timeType = this.$route.params.pubDate
      this.form2.startDate = this.$route.params.date1
      this.form2.endDate = this.$route.params.date2
      this.form2.sn = this.$route.params.sn
      this.showEditNews()
    }
    this.listAllFields()
  },
  methods: {
    async listAllFields () {
      const res = await extendFieldsList({ show: 1 })
      this.extendFields = res.data
    },
    validate () {
      this.isEmpty = false
      if (!this.form.body && this.form.body.length === 0) {
        this.isEmpty = true
      }
      this.form.themeId = this.$route.params.id
    },
    select_status () {
      this.$forceUpdate();
    },
    save () {
      this.saveNews()
    },
    reset () {
      this.type = 'add'
      this.$refs['form'].resetFields()
    },
    toBack () {
      this.$router.replace('/monitor/list/' + this.$route.params.id + '/' + this.$route.params.nodeId)
    },
    // 收起展开
    dropUp () {
      this.openFilter ? (this.openFilter = false) : (this.openFilter = true)
    },
    myContinue () {
      // this.type = 'continued'
      this.$refs.form.resetFields()
      this.form.summary = ''
      this.form.body = ''
    },
    async listAllNode () {
      const res = await listAllNodeWithMS({ msId: this.$route.params.id, nodeId: this.$route.params.nodeId })
      this.node = res.data.data
    },
    async saveNews () {
      this.loading = true
      this.form.themeId = this.$route.params.id
      if (this.form.nodeId === '') {
        this.form.nodeId = this.$route.params.nodeId
      }
      if (this.form.markList === '') {
        this.form.markList = null
      }
      const res = await save(this.form)
      this.loading = false
      if (res.code === 0) {
        this.$message({
          message: '添加成功',
          type: 'success'
        })
        this.myContinue()
      } else {
        this.$message.error('添加失败')
      }
    },
    async showSourceName () {
      try {
        const res = await customSource({ url: this.form.url })
        console.log(res.data)
        this.form.customeSource = res.data
      } catch (e) {
        console.log(e)
      }
    },
    async showEditNews () {
      this.loading = true
      const res = await showOneNewsData(this.form2)
      this.form = res.data.newsDataList[0]
      this.form.oldNodeId = this.form.nodeId
      delete this.form.markList;
      this.form.emotionType = res.data.newsDataList[0].isDanger += ''
      this.$set(this.form,'publishdateStd', res.data.newsDataList[0].publishDate)
      this.form.id = res.data.newsDataList[0].id
      if (res.data.newsDataList[0].customeSource === null || res.data.newsDataList[0].customeSource === '') {
        this.form.customeSource = res.data.newsDataList[0].sourceName
      }
      this.loading = false
    },
    async updateNews () {
      if (this.$route.params.editType === '1') {
        this.form.markList = null
        this.form.nodeId = null
        this.form.themeId = null
      }
      this.form.themeId = this.$route.params.id
      this.loading = true
      const res = await update(this.form)
      this.loading = false
      if (res.code === 0) {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        /* if (this.$route.params.editType === '1') {
          this.$router.replace('/perceptionReport')
        } else { */
          this.$router.replace('/monitor/list/' + this.$route.params.id + '/' + this.$route.params.nodeId)
        // }
      } else {
        this.$message.error('修改失败')
      }
    }
  }
}
</script>

<style lang="scss">
.form-wrap {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: #fff;
  box-shadow: 5px 5px 10px rgba(228, 228, 228, .35);
  padding: 20px 0;
  margin: 0 15px;
  .title {
    font-size: 16px;
    color: #000;
    margin: 0 0 20px 20px;
  }
  .el-form {
    padding: 0 30px;
    .el-form-item {
      &.required {
        label {
          &::before {
            content: '*';
            color: red;
            padding-right: 2px;
          }
        }
      }
      &.publish,&.collect,&.plusMinus,&.sort,&.tag {
        display: inline-block;
      }
      &.publish,&.collect {
        width: 50%;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .warning {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
      }
      .edui-editor{
        width:100% !important;
        z-index: 0 !important;
      }
    }
  }
  .edui-default .edui-editor-iframeholder{
    width: 100% !important;
  }
}
</style>
